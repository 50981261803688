import React from "react";

function ProjectDetailsModal({ isOpen, onClose, project }) {
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={`modal ${isOpen ? "open" : ""}`}
      onClick={handleBackdropClick}
    >
      <div className="modal-content">
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        {project && (
          <>
            <h3 className="modal-header">Project Details</h3>
            {Array.isArray(project.details) ? (
              project.details.map((detail, index) => (
                <p className="modal-paragraph" key={index}>
                  {detail}
                </p>
              ))
            ) : (
              <p className="modal-paragraph">{project.details}</p>
            )}
            <h3 className="modal-header">Technologies Used</h3>
            <ul>
              {project.technologies.map((tech, index) => (
                <li key={index}>{tech}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}

export default ProjectDetailsModal;
