function Intro({ onContactClick }) {
  const handleProjectsClick = () => {
    const projectSection = document.getElementById("projects");
    projectSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="intro">
      <h1>Boris Lucic</h1>
      <h2 className="intro-h2">Web Developer</h2>
      <p>
        My name is Boris, and I am a web developer living in Sweden. When I am
        not coding I enjoy playing tennis and diving into both fiction and
        non-fiction books.
      </p>
      <p>
        With a blend of creativity and technical expertise, I am committed to
        crafting elegant web solutions with a passion for innovation, detail,
        and quality.
      </p>
      <p>Reach out and say hello! 👇</p>
      <div className="intro-buttons">
        <button className="button contact-button" onClick={onContactClick}>
          Contact Me
        </button>
        <button className="button" onClick={handleProjectsClick}>
          My Projects
        </button>
      </div>
    </div>
  );
}

export default Intro;
