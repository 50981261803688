import React from "react";

function Skills() {
  return (
    <div className="skills">
      <h2>Skills</h2>

      <h3>Frontend Development</h3>
      <ul className="skill-list">
        <li className="skill">
          <strong>Languages</strong>{" "}
          <span>HTML | CSS | JavaScript | TypeScript</span>
        </li>
        <li className="skill">
          <strong>Frameworks/Libraries</strong> <span>React | Next.js</span>
        </li>
        <li className="skill">
          <strong>Styling</strong>{" "}
          <span>CSS | SCSS | Tailwind CSS | CSS Modules</span>
        </li>
        <li className="skill">
          <strong>State Management</strong>{" "}
          <span>React Query | Redux | Context API</span>
        </li>
        <li className="skill">
          <strong>Routing</strong> <span>React Router</span>
        </li>
      </ul>

      <h3>Backend Development</h3>
      <ul className="skill-list">
        <li className="skill">
          <strong>Languages & Runtime</strong>{" "}
          <span>Node.js (In-progress)</span>
        </li>
        <li className="skill">
          <strong>Databases</strong> <span>PostgreSQL</span>
        </li>
        <li className="skill">
          <strong>Services</strong> <span>Supabase</span>
        </li>
      </ul>

      <h3>Tools & Technologies</h3>
      <ul className="skill-list">
        <li className="skill">
          <strong>Version Control</strong> <span>Git | GitHub</span>
        </li>
        <li className="skill">
          <strong>Package Managers</strong> <span>npm | Yarn</span>
        </li>
        <li className="skill">
          <strong>Build Tools</strong> <span>Webpack | Babel</span>
        </li>
      </ul>

      <h3>Languages</h3>
      <ul className="skill-list">
        <li className="skill">
          <strong>Fluent</strong>{" "}
          <span>English | Swedish | German | Bosnian</span>
        </li>
      </ul>
    </div>
  );
}

export default Skills;

// The bellow structure won't load/fade-in correctly
// When time allows, find the bug

// import React from "react";

// function Skills() {
//   return (
//     <div className="skills">
//       <h2>Skills</h2>

//       <h3>Frontend Development</h3>
//       <div className="skill-card">
//         <strong>Languages</strong>
//         <ul className="skill-list">
//           <li className="skill">HTML</li>
//           <li className="skill">CSS</li>
//           <li className="skill">JavaScript</li>
//         </ul>
//       </div>

//       <div className="skill-card">
//         <strong>Frameworks/Libraries</strong>
//         <ul className="skill-list">
//           <li className="skill">React</li>
//           <li className="skill">Next.js</li>
//         </ul>
//       </div>

//       <div className="skill-card">
//         <strong>Styling</strong>
//         <ul className="skill-list">
//           <li className="skill">CSS</li>
//           <li className="skill">SCSS</li>
//           <li className="skill">Tailwind CSS</li>
//         </ul>
//       </div>

//       <div className="skill-card">
//         <strong>State Management</strong>
//         <ul className="skill-list">
//           <li className="skill">Redux</li>
//           <li className="skill">Context API</li>
//         </ul>
//       </div>

//       <div className="skill-card">
//         <strong>Routing</strong>
//         <ul className="skill-list">
//           <li className="skill">React Router</li>
//         </ul>
//       </div>

//       <h3>Tools & Technologies</h3>
//       <div className="skill-card">
//         <strong>Version Control</strong>
//         <ul className="skill-list">
//           <li className="skill">Git</li>
//           <li className="skill">GitHub</li>
//         </ul>
//       </div>

//       <div className="skill-card">
//         <strong>Package Managers</strong>
//         <ul className="skill-list">
//           <li className="skill">npm</li>
//           <li className="skill">Yarn</li>
//         </ul>
//       </div>

//       <div className="skill-card">
//         <strong>Build Tools</strong>
//         <ul className="skill-list">
//           <li className="skill">Webpack</li>
//           <li className="skill">Babel</li>
//         </ul>
//       </div>

//       <h3>Languages</h3>
//       <div className="skill-card">
//         <strong>Fluent</strong>
//         <ul className="skill-list">
//           <li className="skill">English</li>
//           <li className="skill">Swedish</li>
//           <li className="skill">German</li>
//           <li className="skill">Bosnian</li>
//         </ul>
//       </div>
//     </div>
//   );
// }

// export default Skills;
