import React, { useState } from "react";
import emailjs from "emailjs-com";

function ContactForm({ isOpen, onClose }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || !email || !message) {
      alert("All fields are required.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    const templateParams = {
      name,
      email,
      message,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setSubmitted(true);
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("FAILED...", error);
        alert("Failed to send email");
      });
  };

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        {submitted ? (
          <p>Thank you for your message! I'll get back to you soon.</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <h3 className="modal-header">Contact Form</h3>
            <h4>
              Name
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Your Name"
                required
              />
            </h4>
            <h4>
              Email
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your Email"
                required
              />
            </h4>
            <h4>
              Message
              <textarea
                className="textarea-custom"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Your Message"
                required
              ></textarea>
            </h4>
            <button type="submit">Submit</button>
            <button
              type="reset"
              onClick={() => {
                setName("");
                setEmail("");
                setMessage("");
              }}
            >
              Reset
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default ContactForm;
