import React, { useState } from "react";
import Intro from "./Intro";
import Skills from "./Skills";
import Projects from "./Projects";
import Footer from "./Footer";
import ContactForm from "./ContactForm";
import ProjectDetailsModal from "./ProjectDetailsModal";
import IntersectionObserverComponent from "./IntersectionObserverComponent";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleContactClick = () => {
    setIsModalOpen(true);
  };

  const handleMoreInfoClick = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  return (
    <div className="App">
      <IntersectionObserverComponent>
        <Intro onContactClick={handleContactClick} />
      </IntersectionObserverComponent>
      <IntersectionObserverComponent>
        <Skills />
      </IntersectionObserverComponent>
      <IntersectionObserverComponent>
        <Projects onMoreInfoClick={handleMoreInfoClick} />
      </IntersectionObserverComponent>
      <Footer />
      <ContactForm isOpen={isModalOpen} onClose={handleModalClose} />
      <ProjectDetailsModal
        isOpen={!!selectedProject}
        onClose={handleModalClose}
        project={selectedProject}
      />
    </div>
  );
}

export default App;
