import React from "react";

function Projects({ onMoreInfoClick }) {
  const projects = [
    {
      name: "Qualithic",
      image: "/Qualithic.jpg",
      details: [
        "Qualithic is a go-to platform for discovering high-quality items and products.",
        "Experience the convenience of finding 'buy it for life' items effortlessly.",
      ],
      technologies: ["HTML", "CSS", "React", "Supabase"],
      liveSite: "https://qualithic.com/",
      moreInfo: true,
    },
    {
      name: "TBA: Travel Companion",
      image: "/ComingSoon.png",
      details: [
        "Prepare for your next adventure with the ultimate travel companion.",
        "Offering the latest information about your next destination, you can search by city and find everything you need for your trip.",
        "Coming Q4 2024.",
      ],
      technologies: ["React", "JavaScript", "CSS", "HTML"],
      liveSite: null,
      moreInfo: false,
    },
    {
      name: "TBA: Educational app",
      image: "/ComingSoon.png",
      details: [
        "This educational app is designed specifically for the blind.",
        "It allows seamless navigation through searches, modification of queries, and complete control with voice commands.",
        "Coming Q1 2025.",
      ],
      technologies: ["React", "JavaScript", "CSS", "HTML"],
      liveSite: null,
      moreInfo: false,
    },
  ];

  return (
    <>
      <h2>Projects</h2>
      <div className="projects" id="projects">
        {projects.map((project, index) => (
          <div className="project-card" key={index}>
            <img src={project.image} alt={project.name} />
            <div className="project-card-content">
              <h3>{project.name}</h3>
              {project.details.map((detail, i) => (
                <p key={i}>{detail}</p>
              ))}
              <div className="project-card-buttons">
                <button disabled={!project.liveSite}>
                  {project.liveSite ? (
                    <a
                      href={project.liveSite}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Live Site
                    </a>
                  ) : (
                    "Live Site"
                  )}
                </button>
                <button
                  onClick={() => onMoreInfoClick(project)}
                  disabled={!project.moreInfo}
                >
                  More Info
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Projects;
